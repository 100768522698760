import { button } from '@/components/Button/button';
import { typography } from '@/components/typography/typography';
import { cx } from 'class-variance-authority';
import Image from 'next/image';
import Link from 'next/link';
import React, { FC } from 'react';
import { isBrowser } from 'react-device-detect';

interface Props {
  className?: string;
}

export const LiveDemoAnnouncement: FC<Props> = ({ className }) => {
  return (
    <>
      {
      isBrowser ?
      <div
        className={cx(
          'max-w-[115.2rem] w-full bg-[#181230] text-white flex items-center justify-between gap-6 rounded-[4rem] pl-24 pr-12',
          className,
        )}
      >
        <div className="flex flex-col w-[47.7rem] gap-6 items-start">
          <h2 className="text-4xl leading-snug">Live Demo</h2>
          <p className={typography({ type: 'headingDescription', color: 'white' })}>
            The easier way to get the best strategy for your business through our machine learning technology.
          </p>
          <Link
            href="/contact-us"
            className={button({
              variant: 'secondary',
              tint: 'white',
            })}
          >
            Request a demo
          </Link>
        </div>
        <Image src="/images/announcement.png" alt="" width={428} height={334} className="w-[42.8rem] h-[33.4rem]" />
      </div>
      :
      <div className='mx-4'>
        <div
          className={cx(
            'max-w-[115.2rem] w-full bg-[#181230] text-white items-center justify-between gap-6 rounded-[4rem] px-14 py-8',
            className,
          )}
        >
          <div className='flex items-center'>
            <Image src="/images/announcement.png" alt="" 
            width={428} height={428} 
            className="w-full h-full mb-8" />
          </div>
          <div className="flex flex-col gap-6 items-center">
            <h2 className="text-4xl leading-snug text-center">Live Demo</h2>
            <p className={typography({ type: 'headingDescription', color: 'white', className: 'text-center' })}>
              The easier way to get the best strategy for your business through our machine learning technology.
            </p>
            <Link
              href="/contact-us"
              className={button({
                variant: 'secondary',
                tint: 'white',
              })}
            >
              Request a demo
            </Link>
          </div>
        </div>
      </div>
      }
    </>
  );
};
