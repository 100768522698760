import { fetcher } from '@/utils/fetcher';
import { GenericResponse, Option } from '../types';
import {
  DataServices_GetKeywordSuggestions_KeywordSuggestions,
  DataServices_GetMentionOvertime_Value,
  DataServices_GetSocialEngagementByKeywordUUID_Value,
  DataServices_GetSocialReachByKeywordUUID_Value,
  DataServices_GetTotalMentionByKeywordUUID_Value,
} from './models';
import { DataServices_GetArticle, DataServices_GetArticleCategories } from './models/get-article';

const BASE_URL = process.env.NEXT_PUBLIC_BE_URL;

export enum DataServicesKey {
  getKeywordSuggestions = 'DataServices.getKeywordSuggestions',
  getTotalMentionByKeywordUUID = 'DataServices.getTotalMentionByKeywordUUID',
  getSocialReachByKeywordUUID = 'DataServices.getSocialReachByKeywordUUID',
  getSocialEngagementByKeywordUUID = 'DataServices.getSocialEngagementByKeywordUUID',
  getMentionOvertime = 'DataServices.getMentionOvertime',
  getArticleCategory = 'DataServices.getArticleCategory',
  getArticles = 'DataServices.getArticles',
  getArticleDetail = 'DataServices.getArticleDetail',
}

export class DataServices {
  static async getArticleCategories(option: Option = {}) {
    const response = (await fetcher(BASE_URL + '/api/v1/article/category' + (option?.query ?? ''), {
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
    })) as GenericResponse<DataServices_GetArticleCategories[]>;
    return response.data;
  }
  static async getArticles(option: Option = {}) {
    const response = (await fetcher(BASE_URL + '/api/v1/article/' + (option?.query ?? ''), {
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
    })) as GenericResponse<DataServices_GetArticle[]>;
    return response.data;
  }

  static async getArticleDetail(UUID: string) {
    const response = (await fetcher(BASE_URL + '/api/v1/article/' + UUID, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
    })) as GenericResponse<DataServices_GetArticle>;
    return response.data;
  }

  static async getKeywordSuggestions(option: Option = {}) {
    const response = (await fetcher(BASE_URL + '/api/v1/trending/keyword-suggestion' + (option?.query ?? ''), {
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
    })) as GenericResponse<DataServices_GetKeywordSuggestions_KeywordSuggestions[]>;
    return response.data;
  }

  static async getTotalMentionByKeywordUUID(keywordUUID: string) {
    const response = (await fetcher(BASE_URL + '/api/v1/trending/total-mention?keyword_group_uuid=' + keywordUUID, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
    })) as GenericResponse<DataServices_GetTotalMentionByKeywordUUID_Value>;
    return response.data;
  }

  static async getSocialReachByKeywordUUID(keywordUUID: string) {
    const response = (await fetcher(BASE_URL + '/api/v1/trending/social-reach?keyword_group_uuid=' + keywordUUID, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
    })) as GenericResponse<DataServices_GetSocialReachByKeywordUUID_Value>;
    return response.data;
  }

  static async getSocialEngagementByKeywordUUID(keywordUUID: string) {
    const response = (await fetcher(BASE_URL + '/api/v1/trending/social-engagement?keyword_group_uuid=' + keywordUUID, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
    })) as GenericResponse<DataServices_GetSocialEngagementByKeywordUUID_Value>;
    return response.data;
  }

  static async getMentionOvertime(option: Option) {
    const response = (await fetcher(BASE_URL + '/api/v1/trending/mentions-over-time' + (option?.query ?? ''), {
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
    })) as GenericResponse<DataServices_GetMentionOvertime_Value[]>;
    return response.data;
  }
}
